import http from 'lib/axios';
import { BrandWithProducts } from 'types/brand';
import { Price } from 'types/price';
import { User } from 'types/user';

type LoginResponse = {
  access_token: string;
  refresh_token: string;
  user: User;
};

type RefreshTokenResponse = Pick<LoginResponse, 'access_token' | 'refresh_token'>;

const API = {
  login: (data: Record<string, unknown>) => http.post<LoginResponse>('/auth/signIn', data),
  register: (data: Record<string, unknown>) => http.post<LoginResponse>('/auth/signUp', data),
  recovery: (data: Record<string, unknown>) => http.post<{ email: string }>('/auth/recovery', data),
  confirmPassword: (data: Record<string, unknown>) =>
    http.post<{ success: boolean }>('/auth/confirm-password', data),
  me: () => http.get<User>('/auth/me'),
  refreshAccessToken: (refreshToken: string) =>
    http.post<RefreshTokenResponse>('/auth/refresh-token', { refreshToken }),

  // Brands
  getBrands: (params?: Record<string, unknown>) =>
    http.get<BrandWithProducts[]>('/brand', { params }),
  getBrandById: (brandId: number) => http.get<BrandWithProducts>(`/brand/${brandId}`),
  createBrand: (data: Record<string, unknown>) => http.post('/brand', data),
  deleteBrand: (id?: number) => http.delete(`/brand/${id}`),
  updateBrand: (id: string, data: Record<string, unknown>) => http.patch(`/brand/${id}`, data),

  // Product
  createProduct: (data: Record<string, unknown>) => http.post('/product', data),
  getProduct: (id: string) => http.get(`/product/${id}`),
  deleteProduct: (id: number) => http.delete(`/product/${id}`),
  updateProduct: (id?: number, data?: Record<string, unknown>) =>
    http.patch(`/product/${id}`, data),

  // Price
  createPrice: (data: Record<string, unknown>) => http.post('/price', data),
  getPrices: () => http.get('/price'),
  deletePrice: (id: number) => http.delete(`/price/${id}`),
  getPublicPrice: () => http.get('/price/public'),
  getPriceById: (id: string) => http.get<Price>(`/price/${id}`),
  updatePrice: (id: string, data: Record<string, unknown>) => http.patch(`/price/${id}`, data),

  // Storage
  storageUpload: (data: FormData) => http.post('/storage', data),

  // Users
  getUsers: () => http.get<User[]>('/users'),
  updateUser: (id: number, data?: Record<string, unknown>) => http.patch(`/users/${id}`, data),
  deleteUser: (id: number) => http.delete<boolean>(`/users/${id}`),
};

export default API;
