import './Home.css';
import { useContext, useEffect, useState } from 'react';
import { Price } from 'types/price';
import API from 'lib/api';
import { HomeHeader } from './Header';
import { SkeletonLoading } from 'components/SkeletonLoading';
import { Box, IconButton, Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { BrandWithProducts } from 'types/brand';
import ColorModeContext from 'ThemeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LoginIcon from '@mui/icons-material/Login';
import { Item } from './Item';
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  const [price, setPrice] = useState<Price>();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();

  useEffect(() => {
    const getPrice = async () => {
      try {
        const { data } = await API.getPublicPrice();
        setPrice(data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    getPrice();
  }, []);

  return (
    <div className="home">
      <HomeHeader />
      {isLoading && <SkeletonLoading count={10} />}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {price?.priceUrl && (
          <Link href={price?.priceUrl} target="_blank" sx={{ display: 'flex' }}>
            <DownloadForOfflineIcon sx={{ mr: 1 }} />
            Скачать прайс в PDF
          </Link>
        )}
        <Box>
          <Tooltip title="Сменить тему">
            <IconButton
              sx={{ ml: 1, mr: { xs: 1 } }}
              onClick={colorMode.toggleColorMode}
              color="inherit"
            >
              {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Войти на сайт">
            <IconButton
              sx={{ ml: 1, mr: { xs: 1 } }}
              onClick={() => navigate('/login')}
              color="inherit"
            >
              <LoginIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      {!isLoading && !price && <Typography>Актуальный прайс пока что не опубликован.</Typography>}
      {!isLoading && price && (
        <Box sx={{ mt: 3, overflowX: 'auto' }}>
          <Box sx={{ minWidth: 600, whiteSpace: 'nowrap' }}>
            <Box
              sx={{
                display: 'flex',
                bgcolor: 'action.active',
                color: 'background.default',
                fontSize: 18,
                mb: 2,
              }}
            >
              <Box sx={{ flex: 1, p: 2 }}>Наименование</Box>
              <Box sx={{ flexBasis: 150, p: 2 }}>Кол/объем</Box>
              <Box sx={{ flexBasis: 100, p: 2 }}>Цена</Box>
            </Box>
            {JSON.parse(price.data).map((brand: BrandWithProducts) => (
              <Item key={brand.id} brand={brand} price={price} />
            ))}
          </Box>
        </Box>
      )}
    </div>
  );
};
