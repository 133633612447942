import { Box, Paper, TextField, Link, Alert } from '@mui/material';
import * as s from '../RegisterForm/styled';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import API from 'lib/api';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { useAuth } from 'hooks/auth';
import { Logo } from 'components/Logo';
import { useNotification } from 'hooks/notification';

type Inputs = {
  password: string;
};

export const ConfirmPasswordForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<Inputs>({ defaultValues: { password: '' }, mode: 'onChange' });
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const clearError = () => {
    if (errorMessage) setErrorMessage('');
  };

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      setIsLoading(true);
      const params = new URLSearchParams(document.location.search);
      const token = params.get('token');
      const id = params.get('id');
      const payload = { ...formData, token, userId: id };
      const { request } = await API.confirmPassword(payload);
      if (request.status > 409) {
        setErrorMessage('Неправильная ссылка для восстановления пароля!');
      } else if (request.status > 250) {
        setErrorMessage('Произошла ошибка, повторите запрос');
      } else {
        showNotification('Пароль успешно изменен!', 'success');
        setIsSuccess(true);
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  if (user) return <Navigate to="/" />;

  return (
    <s.Container>
      <s.Wrapper>
        <Paper elevation={1} sx={{ p: 5 }}>
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Logo />
            <h3 style={{ marginTop: 4 }}>Новый пароль</h3>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              error={!!errors.password?.message}
              fullWidth
              margin="normal"
              label="Пароль"
              variant="outlined"
              helperText={errors.password?.message}
              disabled={isSuccess}
              onFocus={clearError}
              {...register('password', {
                minLength: { value: 8, message: 'Пароль должен содержать минимум 8 символов' },
                required: 'Поле обязательно для заполнения',
              })}
            />

            <Box
              sx={{
                textAlign: 'right',
                marginTop: 2,
              }}
            >
              <LoadingButton
                fullWidth
                loading={isLoading}
                size="large"
                variant="contained"
                type="submit"
                disabled={isSuccess || !isValid}
              >
                Обновить пароль
              </LoadingButton>
            </Box>
            {errorMessage && (
              <Alert sx={{ marginTop: 2 }} severity="error">
                {errorMessage}
              </Alert>
            )}
            {isSuccess && (
              <Alert sx={{ marginTop: 2 }} severity="success">
                Через 3 секунды вас переведет на страницу авторизации.
              </Alert>
            )}
          </form>
        </Paper>
        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
          <Link component={RouterLink} to="/login">
            Вход на сайт
          </Link>
        </Box>
      </s.Wrapper>
    </s.Container>
  );
};
