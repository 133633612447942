import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { BrandWithProductsChecked, ProductWithChecked } from 'types/brand';
import { Currency } from './Add';
import { PriceText } from 'components/PriceText';

const symbolCurrency = {
  rub: '₽',
  eur: '€',
  usd: '$',
};

type PriceBrandProps = {
  brand: BrandWithProductsChecked;
  handleCheck: (id: number, checked: boolean) => void;
  handleCheckAll: (brandId: number, checked: boolean) => void;
  isLast: boolean;
  currency: Currency;
  currencies?: Currency[];
  handleChangeRoz?: (brandId: number, productId: number, value: string) => void;
  handleChangeOpt?: (brandId: number, productId: number, value: string, currency: Currency) => void;
  handleChangePageBreaker?: (brandId: number, checked: boolean) => void;
  type: string;
  handleChangeProduct?: (
    brandId: number,
    productId: number,
    prop: keyof ProductWithChecked,
    value: unknown,
  ) => void;
};
export const PriceBrand: React.FC<PriceBrandProps> = ({
  brand,
  isLast,
  handleCheck,
  handleCheckAll,
  currency,
  currencies,
  handleChangeRoz,
  handleChangeOpt,
  handleChangePageBreaker,
  type,
  handleChangeProduct,
}) => {
  const hasChecked = brand.products.some((product) => product.checked);
  const isAllChecked = brand.products.every((product) => product.checked);

  return (
    <Box mb={4}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
        sx={{ mb: 2 }}
      >
        <FormControlLabel
          sx={{ mb: 1 }}
          componentsProps={{ typography: { variant: 'h6' } }}
          control={
            <Checkbox
              checked={isAllChecked}
              indeterminate={hasChecked && !isAllChecked}
              onChange={(_, checked) => handleCheckAll(brand.id, checked)}
              color={isAllChecked ? 'primary' : 'default'}
            />
          }
          label={brand.name}
        />
        {handleChangePageBreaker && (
          <FormControlLabel
            sx={{ mb: 1 }}
            control={
              <Checkbox
                checked={brand.isPageBreak}
                onChange={(_, checked) => handleChangePageBreaker(brand.id, checked)}
              />
            }
            label="Перенос на новую страницу"
          />
        )}
      </Stack>
      <FormGroup>
        {brand.products.map((product) => (
          <div>
            <Stack
              justifyContent="space-between"
              direction={{ xs: 'column', lg: 'row' }}
              flexWrap="wrap"
              key={product.id}
              sx={{ mb: 1 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={product.checked}
                    onChange={(_, checked) => handleCheck(product.id, checked)}
                  />
                }
                sx={{
                  color: '#aaa',
                  '& .MuiSvgIcon-root': { fontSize: 20 },
                  flex: { xs: 'auto', sm: '1' },
                }}
                label={product.title}
              />
              {type === 'roz' && (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    pl: { xs: 3.5, lg: 0 },
                  }}
                >
                  {handleChangeRoz ? (
                    <TextField
                      value={product.price[currency]}
                      onChange={(e) => handleChangeRoz(brand.id, product.id, e.target.value)}
                      label="Цена"
                      variant="outlined"
                      size="small"
                      sx={{ width: 130 }}
                    />
                  ) : (
                    <PriceText value={product.price[currency]} symbol={symbolCurrency[currency]} />
                  )}
                </Box>
              )}
              {type === 'opt' && (
                <>
                  {handleChangeOpt ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: { xs: 'nowrap', lg: 'wrap' },
                        pl: { xs: 2, lg: 0 },
                      }}
                    >
                      {currencies?.map((curr) => (
                        <TextField
                          value={product.priceOpt[curr]}
                          onChange={(e) =>
                            handleChangeOpt(brand.id, product.id, e.target.value, curr)
                          }
                          label={symbolCurrency[curr]}
                          variant="outlined"
                          size="small"
                          sx={{ ml: 1, width: { xs: '100%', lg: 130 } }}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: { xs: 'nowrap', lg: 'wrap' },
                        pl: { xs: 4, lg: 0 },
                      }}
                    >
                      {currencies?.map((curr) => (
                        <PriceText
                          value={product.priceOpt[curr]}
                          symbol={symbolCurrency[curr]}
                          width={60}
                        />
                      ))}
                    </Box>
                  )}
                </>
              )}
            </Stack>
            {handleChangeProduct && (
              <>
                <FormControlLabel
                  sx={{ pl: { xs: 4, lg: 0 } }}
                  control={
                    <Switch
                      size="small"
                      checked={product.new}
                      onChange={(_, checked) =>
                        handleChangeProduct?.(brand.id, product.id, 'new', checked)
                      }
                    />
                  }
                  label="NEW"
                />
                <FormControlLabel
                  sx={{ pl: { xs: 2, lg: 0 } }}
                  control={
                    <Switch
                      size="small"
                      checked={product.promo}
                      onChange={(_, checked) =>
                        handleChangeProduct?.(brand.id, product.id, 'promo', checked)
                      }
                    />
                  }
                  label="Акция"
                />
                <Divider sx={{ mb: 1, mt: 1 }} />
              </>
            )}
          </div>
        ))}
      </FormGroup>
      {!isLast && !handleChangeProduct && <Divider sx={{ mt: 2 }} />}
    </Box>
  );
};
