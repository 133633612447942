import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Modal } from 'components/Modal';
import { useNotification } from 'hooks/notification';
import API from 'lib/api';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { User } from 'types/user';

type EditUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedUser?: User;
  getUsers: () => void;
};

type Inputs = {
  role: string;
};

export const EditUserModal: React.FC<EditUserModalProps> = ({
  isOpen,
  onClose,
  selectedUser,
  getUsers,
}) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<Inputs>({ mode: 'onChange' });

  const { showNotification } = useNotification();

  const [isSending, setIsSending] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsSending(true);

    try {
      if (selectedUser) {
        const { request } = await API.updateUser(selectedUser.id, data);
        if (request.status >= 400) {
          const { message } = JSON.parse(request.response);
          showNotification(message, 'error');
        } else {
          showNotification('Роль успешно обновлена', 'success');
          getUsers();
          onClose();
        }
      }
    } catch (error) {
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Modal
      title="Обновление пользователя"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      okDisabled={!isValid}
      isLoading={isSending}
    >
      <FormControl fullWidth error={Boolean(errors.role?.message)}>
        <InputLabel id="select-role">Выбрать роль</InputLabel>
        <Select
          labelId="select-role"
          id="demo-simple-select"
          required
          label="Выбрать роль"
          defaultValue={selectedUser?.role ?? 'USER'}
          {...register('role', { required: 'Необходимо выбрать роль' })}
        >
          <MenuItem value="ADMIN">Админ</MenuItem>
          <MenuItem value="EMPLOYEE">Работник</MenuItem>
          <MenuItem value="USER">Пользователь</MenuItem>
        </Select>
        {errors.role?.message && <FormHelperText>{errors.role.message}</FormHelperText>}
      </FormControl>
    </Modal>
  );
};
