import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

type BrandHeaderProps = {
  onOpenModal: () => void;
};

export const BrandHeader: React.FC<BrandHeaderProps> = ({ onOpenModal }) => {
  const navigate = useNavigate();
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
      alignItems={{ xs: 'flex-start', sm: 'space-between' }}
      spacing={{ xs: 0, sm: 2 }}
      mb={2}
    >
      <Typography sx={{ typography: { xs: 'h6', sm: 'h5', md: 'h4' } }} gutterBottom>
        <IconButton sx={{ mr: 1 }} onClick={() => navigate('/')}>
          <ArrowBackIcon />
        </IconButton>
        Список брендов
      </Typography>
      <Button startIcon={<AddIcon />} onClick={onOpenModal}>
        Добавить бренд
      </Button>
    </Stack>
  );
};
