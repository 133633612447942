import { useAuth } from 'hooks/auth';
import { Prices } from './Prices/Prices';
import { Home } from './Home/Home';
import { isAdmin, isUser } from 'helpers/permission';
import { Typography } from '@mui/material';

const HomePage = () => {
  const { user } = useAuth();

  if (user && isAdmin(user.role)) {
    return <Prices />;
  }

  if (user && isUser(user.role)) {
    return (
      <>
        <Typography variant="h4">Приветствуем, {user.name || 'дорогой'}!</Typography>
        <Typography variant="body1" mt={1}>
          К сожалению личный кабинет для пользователя пока что не сделан.
        </Typography>
      </>
    );
  }

  return <Home />;
};

export default HomePage;
