import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { User } from 'types/user';
import { IconButton, Tooltip, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SkeletonLoading } from 'components/SkeletonLoading';
import { useAuth } from 'hooks/auth';

const roles = {
  USER: 'Пользователь',
  ADMIN: 'Админ',
  EMPLOYEE: 'Работник',
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.selected,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type UsersTableProps = {
  onSelectUser: (user: User) => void;
  onDeleteUser: (user: User) => void;
  users: User[];
  isLoadingUsers: boolean;
};

export const UsersTable: React.FC<UsersTableProps> = ({
  onSelectUser,
  onDeleteUser,
  users,
  isLoadingUsers,
}) => {
  const { user: userAuth } = useAuth();
  if (isLoadingUsers) return <SkeletonLoading count={7} />;
  return (
    <TableContainer component={Paper} sx={{ bgcolor: 'background.default' }}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Имя пользователя</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Роль</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <StyledTableRow key={user.id}>
              <TableCell component="th" scope="row">
                {user.name || '<Пользователь не ввел имя>'}
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{roles[user.role]}</TableCell>
              <TableCell align="right">
                {user.email !== userAuth?.email && (
                  <>
                    <Tooltip title="Редактировать роль" placement="top">
                      <IconButton onClick={() => onSelectUser(user)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Удалить пользователя" placement="top">
                      <IconButton onClick={() => onDeleteUser(user)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
