import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { SkeletonLoading } from 'components/SkeletonLoading';
import API from 'lib/api';
import { useCallback, useEffect, useState } from 'react';
import { BrandWithProductsChecked } from 'types/brand';
import { PriceBrand } from './PriceBrand';
import { LoadingButton } from '@mui/lab';
import { useNotification } from 'hooks/notification';
import { useNavigate } from 'react-router-dom';
import { formatBrands } from './helpers';

export type Currency = 'rub' | 'eur' | 'usd';
export type PriceType = 'opt' | 'roz';
export type PriceTheme = 'white' | 'black';

const PriceAdd = () => {
  const currentDate = new Date();
  const PRICE_TITLE = `Feeller Price - ${currentDate.toLocaleString()}`;
  const [brands, setBrands] = useState<BrandWithProductsChecked[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [typePrice, setTypePrice] = useState<PriceType>('roz');
  const [currency, setCurrency] = useState<Currency>('rub');
  const [currencies, setCurrencies] = useState<Currency[]>(['rub']);
  const [isPublic, setIsPublic] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [priceTitle, setPriceTitle] = useState(PRICE_TITLE);
  const [theme, setTheme] = useState<PriceTheme>('white');

  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const handleChangeTypePrice = (e: SelectChangeEvent<PriceType>) => {
    setTypePrice(e.target.value as PriceType);
  };

  const handleChangeTypeCurrency = (e: SelectChangeEvent<Currency>) => {
    setCurrency(e.target.value as Currency);
  };

  const handleChangePublicPrice = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsPublic(checked);
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPriceTitle(e.target.value);
  };

  const handleChangeTheme = (e: SelectChangeEvent<PriceTheme>) => {
    setTheme(e.target.value as PriceTheme);
  };

  const handleCheckAll = useCallback((brandId: number, checked: boolean) => {
    setBrands((data) =>
      data.map((brand) => {
        if (brand.id === brandId) {
          return {
            ...brand,
            products: brand.products.map((product) => ({
              ...product,
              checked,
            })),
          };
        }

        return brand;
      }),
    );
  }, []);

  const handleCheck = useCallback((id: number, checked: boolean) => {
    setBrands((prev) =>
      prev.map((brand) => ({
        ...brand,
        products: brand.products.map((product) => ({
          ...product,
          checked: product.id === id ? checked : product.checked,
        })),
      })),
    );
  }, []);

  const onCreatePrice = async () => {
    setIsCreating(true);
    try {
      const payload = {
        title: priceTitle,
        isPublic,
        currency,
        type: typePrice,
        currencies,
        data: JSON.stringify(brands),
        theme,
      };
      const { request } = await API.createPrice(payload);

      if (request.status >= 400) {
        const { message } = JSON.parse(request.response);
        showNotification(message, 'error');
      } else {
        showNotification('Прайс успешно создан', 'success');
        navigate('/');
      }
    } catch (error) {
    } finally {
      setIsCreating(false);
    }
  };

  useEffect(() => {
    const getBrandWithProducts = async () => {
      try {
        const { data } = await API.getBrands({ include: 'products', hideEmpty: '' });
        setBrands(formatBrands(data));
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    getBrandWithProducts();
  }, []);
  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
        alignItems={{ xs: 'stretch', sm: 'center' }}
        mb={2}
      >
        <TextField
          size="small"
          placeholder="Название прайса"
          value={priceTitle}
          onChange={handleChangeTitle}
          fullWidth
          sx={{ mr: 1 }}
        />
        <Box sx={{ display: 'flex', ml: { xs: 0, sm: 3 }, mt: { xs: 1 } }}>
          <FormControlLabel
            control={<Switch checked={isPublic} onChange={handleChangePublicPrice} />}
            label="Публичный"
          />
        </Box>
      </Stack>
      <Stack
        flexWrap="wrap"
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'stretch', sm: 'center' }}
        mb={3}
      >
        {typePrice === 'roz' && (
          <FormControl sx={{ flex: 1 }} size="small">
            <InputLabel id="currency">Валюта</InputLabel>
            <Select
              labelId="currency"
              id="typePrice"
              value={currency}
              label="Валюта"
              onChange={handleChangeTypeCurrency}
            >
              <MenuItem value="rub">Рубль</MenuItem>
              <MenuItem value="eur">Евро</MenuItem>
              <MenuItem value="usd">Доллар</MenuItem>
            </Select>
          </FormControl>
        )}
        {typePrice === 'opt' && (
          <FormControl sx={{ flex: 1 }} size="small">
            <InputLabel id="currencies">Валюты</InputLabel>
            <Select
              labelId="currencies"
              id="currencies"
              value={currencies}
              label="Валюты"
              multiple
              onChange={(ev) => setCurrencies(ev.target.value as Currency[])}
            >
              <MenuItem value="rub">Рубль</MenuItem>
              <MenuItem value="eur">Евро</MenuItem>
              <MenuItem value="usd">Доллар</MenuItem>
            </Select>
          </FormControl>
        )}
        <FormControl sx={{ flex: 1 }} size="small">
          <InputLabel id="typePriceLabel">Тип прайса</InputLabel>
          <Select
            labelId="typePriceLabel"
            id="typePrice"
            value={typePrice}
            label="Тип прайса"
            onChange={handleChangeTypePrice}
          >
            <MenuItem value="roz">Розница</MenuItem>
            <MenuItem value="opt">Оптом</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ flex: 1 }} size="small">
          <InputLabel id="themePriceLabel">Вид прайса</InputLabel>
          <Select
            labelId="themePriceLabel"
            id="themePriceLabel"
            value={theme}
            label="Тип прайса"
            onChange={handleChangeTheme}
          >
            <MenuItem value="white">Светлый</MenuItem>
            <MenuItem value="black">Темный</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      {isLoading && <SkeletonLoading count={10} />}
      {brands.map((brand, idx, array) => (
        <PriceBrand
          key={brand.id}
          brand={brand}
          handleCheck={handleCheck}
          handleCheckAll={handleCheckAll}
          isLast={array.length - 1 === idx}
          currency={currency}
          currencies={currencies}
          type={typePrice}
        />
      ))}
      <Box sx={{ textAlign: 'right', mt: 5 }}>
        <LoadingButton
          variant="contained"
          size="large"
          loading={isCreating}
          onClick={onCreatePrice}
        >
          Создать прайс
        </LoadingButton>
      </Box>
    </>
  );
};

export default PriceAdd;
