import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Brand } from 'types/brand';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type BrandHeaderProps = {
  brand?: Brand;
  onOpenModal: () => void;
};

export const BrandHeader: React.FC<BrandHeaderProps> = ({ brand, onOpenModal }) => {
  const navigate = useNavigate();
  return (
    <Stack
      direction={{ md: 'column', lg: 'row' }}
      justifyContent={{ md: 'flex-start' }}
      alignItems={{ md: 'flex-start', lg: 'center' }}
      spacing={{ xs: 0, sm: 2 }}
      mb={2}
    >
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <IconButton sx={{ mr: 1 }} onClick={() => navigate('/brands')}>
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={{ typography: { xs: 'h6', sm: 'h5', md: 'h4' }, mb: 0 }} gutterBottom>
          {brand?.name}
        </Typography>
      </Box>
      <Button
        startIcon={<AddIcon />}
        onClick={onOpenModal}
        sx={{ justifyContent: { xs: 'flex-start' } }}
      >
        Добавить позицию
      </Button>
    </Stack>
  );
};
