import { Box, Container, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import React from 'react';
import Grid from '@mui/material/Grid';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { useAuth } from 'hooks/auth';
import { isAdmin } from 'helpers/permission';

export const AdminLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { palette } = useTheme();
  const { user } = useAuth();
  return (
    <Box
      sx={{
        bgcolor: palette.mode === 'light' ? '#f5f5f5' : 'background.default',
        color: 'text.primary',
        minHeight: '100vh',
        pb: 4,
      }}
    >
      <Container fixed>
        <Header />
        <Divider sx={{ mt: 3 }} />
        <Grid container spacing={4} sx={{ marginTop: { xs: 1, sm: 3 } }}>
          {isAdmin(user?.role ?? 'USER') && (
            <Grid item xs={12} sm={12} md={3}>
              <Sidebar />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={isAdmin(user?.role ?? 'USER') ? 9 : 12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
