import styled from '@emotion/styled';
import { blue } from '@mui/material/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const Wrapper = styled.div`
  width: 400px;
  padding: 30px 0;

  @media (max-width: 571px) {
    width: 100%;
    padding: 15px;
  }
`;

export const Recovery = styled.div`
  a {
    color: ${blue[500]};
    text-decoration: none;

    &:hover {
      color: ${blue[300]};
    }
  }
`;
