import { UsersHeader } from './UsersHeader';
import { UsersTable } from './UsersTable';
import { useCallback, useEffect, useState } from 'react';
import { User } from 'types/user';
import { EditUserModal } from './EditUserModal';
import API from 'lib/api';
import { DeleteUserModal } from './DeleteUserModal';
import { useNotification } from 'hooks/notification';

const Users = () => {
  const [selectedUser, setSelectedUser] = useState<User>();
  const [deleteUser, setDeleteUser] = useState<User>();
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const { showNotification } = useNotification();

  const handleOpenEditModal = (user: User) => {
    setSelectedUser(user);
  };

  const handleCloseEditModal = () => {
    setSelectedUser(undefined);
  };

  const handleOpenDeleteModal = (user: User) => {
    setDeleteUser(user);
  };

  const getUsers = useCallback(async () => {
    try {
      setIsLoadingUsers(true);
      const { data } = await API.getUsers();
      setUsers(data);
    } catch (error) {
    } finally {
      setIsLoadingUsers(false);
    }
  }, []);

  const onDeleteProduct = useCallback(async () => {
    try {
      if (deleteUser) {
        setIsDeletingProduct(true);
        const { data: isSuccess } = await API.deleteUser(deleteUser.id);
        if (isSuccess) {
          showNotification('Пользователь успешно удален', 'success');
          setDeleteUser(undefined);
          getUsers();
        }
      }
    } catch (error) {
    } finally {
      setIsDeletingProduct(false);
    }
  }, [deleteUser, showNotification, getUsers]);

  const handleCloseDeleteModal = () => {
    setDeleteUser(undefined);
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);
  return (
    <>
      <UsersHeader />
      <UsersTable
        onSelectUser={handleOpenEditModal}
        onDeleteUser={handleOpenDeleteModal}
        users={users}
        isLoadingUsers={isLoadingUsers}
      />
      <EditUserModal
        getUsers={getUsers}
        selectedUser={selectedUser}
        isOpen={Boolean(selectedUser)}
        onClose={handleCloseEditModal}
      />
      <DeleteUserModal
        onClose={handleCloseDeleteModal}
        onOk={onDeleteProduct}
        isLoading={isDeletingProduct}
        isOpen={Boolean(deleteUser)}
        user={deleteUser}
      />
    </>
  );
};

export default Users;
