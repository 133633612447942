import { Grid, TextField, Typography, Switch, FormControlLabel } from '@mui/material';
import { Modal } from 'components/Modal';
import { useNotification } from 'hooks/notification';
import API from 'lib/api';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

type AddProductModalProps = {
  isOpen: boolean;
  onClose: () => void;
  brandId?: number;
  getBrand: (id: string) => void;
};

type Inputs = {
  order: string;
  qty: string;
  title: string;
  priceRub: string;
  priceEur: string;
  priceUsd: string;
  priceOptRub: string;
  priceOptEur: string;
  priceOptUsd: string;
  new: boolean;
  promo: boolean;
};

export const AddProductModal: React.FC<AddProductModalProps> = ({
  isOpen,
  onClose,
  brandId,
  getBrand,
}) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm<Inputs>({ mode: 'onChange' });

  const { showNotification } = useNotification();

  const [isSending, setIsSending] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsSending(true);
    try {
      const payload = {
        order: +data.order,
        title: data.title,
        price: {
          eur: +data.priceEur,
          usd: +data.priceUsd,
          rub: +data.priceRub,
        },
        priceOpt: {
          eur: +data.priceOptEur,
          usd: +data.priceOptUsd,
          rub: +data.priceOptRub,
        },
        qty: data.qty,
        brandId,
        promo: data.promo,
        new: data.new,
      };
      const { request } = await API.createProduct(payload);
      if (request.status >= 400) {
        const { message } = JSON.parse(request.response);
        showNotification(message, 'error');
      } else {
        getBrand(String(brandId));
        reset();
        onClose();
        showNotification(`${data.title} успешно добавлено.`, 'success');
      }
    } catch (error) {
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);
  return (
    <Modal
      title="Добавить позицию"
      isOpen={isOpen}
      onClose={onClose}
      okText="Добавить"
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isSending}
      maxWidth="sm"
      okDisabled={!isValid}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <TextField
            fullWidth
            required
            label="Название"
            defaultValue=""
            error={Boolean(errors.title?.message)}
            helperText={errors.title?.message}
            autoFocus
            {...register('title', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField label="Количество" defaultValue="0" {...register('qty')} />
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            label="Порядок"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue="0"
            {...register('order')}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" mt={2} mb={1}>
        Розничная цена
      </Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="₽"
            defaultValue="0"
            error={Boolean(errors.priceRub?.message)}
            helperText={errors.priceRub?.message}
            {...register('priceRub', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="€"
            defaultValue="0"
            error={Boolean(errors.priceEur?.message)}
            helperText={errors.priceEur?.message}
            {...register('priceEur', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="$"
            defaultValue="0"
            error={Boolean(errors.priceUsd?.message)}
            helperText={errors.priceUsd?.message}
            {...register('priceUsd', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" mt={2} mb={1}>
        Оптовая цена
      </Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="₽"
            defaultValue="0"
            error={Boolean(errors.priceOptRub?.message)}
            helperText={errors.priceOptRub?.message}
            {...register('priceOptRub', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="€"
            defaultValue="0"
            error={Boolean(errors.priceOptEur?.message)}
            helperText={errors.priceOptEur?.message}
            {...register('priceOptEur', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="$"
            defaultValue="0"
            error={Boolean(errors.priceOptUsd?.message)}
            helperText={errors.priceOptUsd?.message}
            {...register('priceOptUsd', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={4}>
          <FormControlLabel control={<Switch {...register('new')} />} label="Новый товар" />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel control={<Switch {...register('promo')} />} label="Акция" />
        </Grid>
      </Grid>
    </Modal>
  );
};
