import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Typography,
} from '@mui/material';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ReorderIcon from '@mui/icons-material/Reorder';
import PeopleIcon from '@mui/icons-material/People';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useAuth } from 'hooks/auth';
import { Role } from 'helpers/permission';

export const Sidebar = () => {
  const [openMenuItem, setOpenMenuItem] = useState('');
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleOpenMenu = (item: string) => {
    setOpenMenuItem((prev) => (prev === item ? '' : item));
  };

  const menu = [
    {
      icon: <ShoppingBagIcon fontSize="small" />,
      handler: () => {
        handleOpenMenu('brands');
      },
      id: 'brands',
      title: 'Бренды',
      role: [Role.admin, Role.employee],
      children: [
        {
          icon: null,
          handler: () => navigate('/brands'),
          title: 'Список брендов',
        },
      ],
    },
    {
      icon: <ReorderIcon fontSize="small" />,
      handler: () => {
        handleOpenMenu('price');
      },
      id: 'price',
      title: 'Прайсы',
      role: [Role.admin, Role.employee],
      children: [
        {
          icon: null,
          handler: () => navigate('/'),
          title: 'Список прайсов',
        },
        {
          icon: null,
          handler: () => navigate('/price-add'),
          title: 'Добавить прайс',
        },
      ],
    },
    {
      id: 'users',
      title: 'Пользователи',
      icon: <PeopleIcon fontSize="small" />,
      handler: () => navigate('/users'),
      role: [Role.admin],
    },
  ];
  return (
    <>
      <Typography variant="h5">Навигация</Typography>
      <MenuList>
        {menu
          .filter((menuItem) => user && menuItem.role.includes(user.role))
          .map((menuItem, idx, array) => {
            const isLast = array.length - 1 === idx;
            return (
              <div key={menuItem.id}>
                <ListItemButton key={menuItem.title} onClick={menuItem.handler}>
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText primary={menuItem.title} />
                  {menuItem.children && menuItem.children.length > 0 && (
                    <>{openMenuItem === menuItem.id ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </ListItemButton>
                <Collapse in={openMenuItem === menuItem.id} timeout="auto" unmountOnExit>
                  {menuItem.children && menuItem.children.length > 0 && (
                    <List component="div" disablePadding>
                      {menuItem?.children?.map((childItem) => (
                        <ListItemButton
                          sx={{ pl: 9 }}
                          key={childItem.title}
                          onClick={childItem.handler}
                          dense
                        >
                          {childItem.icon && <ListItemIcon>{childItem.icon}</ListItemIcon>}
                          {childItem.icon ? (
                            childItem.title
                          ) : (
                            <ListItemText primary={childItem.title} />
                          )}
                        </ListItemButton>
                      ))}
                    </List>
                  )}
                </Collapse>
                {!isLast && <Divider />}
              </div>
            );
          })}
      </MenuList>
    </>
  );
};
