import Typography from '@mui/material/Typography';
import { SkeletonLoading } from 'components/SkeletonLoading';
import API from 'lib/api';
import { useCallback, useEffect, useState } from 'react';
import { Brand } from 'types/brand';
import { BrandItem } from './BrandItem';
import { Grid, List, TextField } from '@mui/material';
import { BrandHeader } from './BrandHeader';
import { Modal } from 'components/Modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNotification } from 'hooks/notification';
import { DeleteBrandModal } from './DeleteBrandModal';
import { UpdateBrandModal } from './UpdateBrandModal';

type Inputs = {
  name: string;
  order: string;
};

const Brands = () => {
  const [isLoadingBrands, setIsLoadingBrands] = useState(true);
  const [isModalCreate, setIsModalCreate] = useState(false);
  const [deleteItem, setDeleteItem] = useState<Brand>();
  const [selectedBrand, setSelectedBrand] = useState<Brand>();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm<Inputs>({ mode: 'onChange' });

  const { showNotification } = useNotification();

  const getBrands = useCallback(async () => {
    try {
      const { data } = await API.getBrands();
      setBrands(data);
    } catch (error) {
    } finally {
      setIsLoadingBrands(false);
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    reset();
    setIsModalCreate(false);
  }, [reset]);

  const handleOpenModal = useCallback(() => {
    setIsModalCreate(true);
  }, []);

  const handleCloseModalDelete = useCallback(() => {
    setDeleteItem(undefined);
  }, []);

  const handleOpenModalDelete = useCallback((brand: Brand) => {
    setDeleteItem(brand);
  }, []);

  const handleCloseModalUpdate = useCallback(() => {
    setSelectedBrand(undefined);
  }, []);

  const handleOpenModalUpdate = useCallback((brand: Brand) => {
    setSelectedBrand(brand);
  }, []);

  const onDeleteBrand = async () => {
    setIsDeleting(true);
    try {
      await API.deleteBrand(deleteItem?.id);
      showNotification(`Бренд ${deleteItem?.name} удален успешно`);
      handleCloseModalDelete();
      getBrands();
    } catch (error) {
    } finally {
      setIsDeleting(false);
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsCreating(true);
    try {
      const payloadBrand = {
        name: data.name,
        order: +data.order,
      };
      const { request } = await API.createBrand(payloadBrand);
      if (request.status >= 200 && request.status < 300) {
        showNotification(`Бренд "${data.name}" успешно создан`, 'success');
        handleCloseModal();
        getBrands();
      } else {
        const { response } = request;
        const { message } = JSON.parse(response);
        showNotification(message, 'error');
      }
    } catch (error) {
    } finally {
      setIsCreating(false);
    }
  };

  useEffect(() => {
    getBrands();
  }, [getBrands]);
  return (
    <>
      <BrandHeader onOpenModal={handleOpenModal} />
      {isLoadingBrands && <SkeletonLoading count={5} />}
      <List>
        {brands.map((brand, idx, array) => (
          <BrandItem
            key={brand.id}
            brand={brand}
            handleOpenModalDelete={handleOpenModalDelete}
            handleOpenModalUpdate={handleOpenModalUpdate}
            isLast={array.length - 1 === idx}
          />
        ))}
      </List>
      {!isLoadingBrands && brands.length === 0 && (
        <Typography variant="body1" gutterBottom>
          Нет брендов в базе
        </Typography>
      )}
      <DeleteBrandModal
        onOk={onDeleteBrand}
        onClose={handleCloseModalDelete}
        isOpen={Boolean(deleteItem)}
        brand={deleteItem}
        isLoading={isDeleting}
      />
      <UpdateBrandModal
        brand={selectedBrand}
        isOpen={Boolean(selectedBrand)}
        onClose={handleCloseModalUpdate}
        getBrands={getBrands}
      />
      <Modal
        title="Создать новый бренд"
        onClose={handleCloseModal}
        isOpen={isModalCreate}
        onSubmit={handleSubmit(onSubmit)}
        okText="Создать"
        isLoading={isCreating}
        okDisabled={!isValid}
        maxWidth="sm"
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              required
              label="Название бренда"
              defaultValue=""
              error={Boolean(errors.name?.message)}
              helperText={errors.name?.message}
              {...register('name', { required: 'Поле обязательно для заполнения' })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Порядок"
              type="number"
              required
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(errors.order?.message)}
              helperText={errors.order?.message}
              defaultValue={brands.length}
              {...register('order', { required: 'Поле обязательно для заполнения' })}
            />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default Brands;
