import { IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const UsersHeader = () => {
  const navigate = useNavigate();
  return (
    <Typography sx={{ typography: { xs: 'h6', sm: 'h5', md: 'h4' } }} gutterBottom>
      <IconButton sx={{ mr: 1 }} onClick={() => navigate('/')}>
        <ArrowBackIcon />
      </IconButton>
      Пользовали
    </Typography>
  );
};
