import React from 'react';
import './spinner.css';
import { PaletteMode } from '@mui/material';

export const Spinner: React.FC<{ text?: string; mode: PaletteMode }> = ({ text, mode }) => {
  return (
    <div className="spinner">
      <div className={`lds-ellipsis ${mode === 'light' && 'lds-ellipsis_dark'}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {text && <div className="spinner__text">{text}</div>}
    </div>
  );
};
