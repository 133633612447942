import { Box, Paper, TextField, Link, Alert } from '@mui/material';
import * as s from '../RegisterForm/styled';
import { Navigate, Link as RouterLink } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import API from 'lib/api';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { useAuth } from 'hooks/auth';
import { Logo } from 'components/Logo';
import { useNotification } from 'hooks/notification';
import { REGEX_EMAIL } from 'constants/regex';

type Inputs = {
  email: string;
};

export const RecoveryForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<Inputs>({ defaultValues: { email: '' }, mode: 'onChange' });
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { showNotification } = useNotification();

  const clearError = () => {
    if (errorMessage) setErrorMessage('');
  };

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      setIsLoading(true);
      const { request } = await API.recovery(formData);
      if (request.status === 404) {
        setErrorMessage('Email нет в системе, для восстановления пароля!');
      } else {
        showNotification('Ссылка на восстановление, отправлена на почту!', 'success');
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  if (user) return <Navigate to="/" />;

  return (
    <s.Container>
      <s.Wrapper>
        <Paper elevation={1} sx={{ p: 5 }}>
          <Box
            sx={{
              textAlign: 'center',
              maxWidth: {
                xs: '200px',
                sm: 'auto',
              },
              margin: 'auto',
            }}
          >
            <Logo />
            <h3 style={{ marginTop: 4 }}>Восстановление пароля</h3>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              error={!!errors.email?.message}
              fullWidth
              margin="normal"
              label="Email"
              variant="outlined"
              helperText={errors.email?.message}
              {...register('email', {
                pattern: {
                  value: REGEX_EMAIL,
                  message: 'Неверный формат для email',
                },
                required: 'Поле обязательно для заполнения',
              })}
              onBlur={clearError}
            />

            <Box
              sx={{
                textAlign: 'right',
                marginTop: 2,
              }}
            >
              <LoadingButton
                fullWidth
                loading={isLoading}
                size="large"
                variant="contained"
                type="submit"
                disabled={!isValid}
              >
                Восстановить пароль
              </LoadingButton>
            </Box>
            {errorMessage && (
              <Alert sx={{ marginTop: 2 }} severity="error">
                {errorMessage}
              </Alert>
            )}
          </form>
        </Paper>
        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
          <Link component={RouterLink} to="/login">
            Вход на сайт
          </Link>
        </Box>
      </s.Wrapper>
    </s.Container>
  );
};
