import { Alert, Snackbar } from '@mui/material';
import { useNotification } from 'hooks/notification';

export const Notification = () => {
  const { isOpen, closeNotification, message, type, delay } = useNotification();
  return (
    <Snackbar open={isOpen} autoHideDuration={delay} onClose={closeNotification}>
      <Alert onClose={closeNotification} severity={type} sx={{ width: '100%' }} elevation={5}>
        {message}
      </Alert>
    </Snackbar>
  );
};
