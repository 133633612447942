import { useMemo, useState, ReactNode, FC, useLayoutEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ColorModeContext from './ThemeContext';

type Theme = 'light' | 'dark';

const ToggleColorMode: FC<{ children: ReactNode }> = ({ children }) => {
  const [mode, setMode] = useState<Theme>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const theme = prevMode === 'light' ? 'dark' : 'light';
          window.localStorage.setItem('theme', theme);
          return theme;
        });
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  useLayoutEffect(() => {
    try {
      const theme = window.localStorage.getItem('theme') as Theme;
      setMode(theme ? theme : 'light');
    } catch (error) {}
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default ToggleColorMode;
