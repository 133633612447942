import Typography from '@mui/material/Typography';
import { SkeletonLoading } from 'components/SkeletonLoading';
import API from 'lib/api';
import { BrandWithProducts } from 'types/brand';
import { useCallback, useEffect, useState } from 'react';
import { ProductItem } from './ProductItem';
import { useParams } from 'react-router-dom';
import { BrandHeader } from './BrandHeader';
import { AddProductModal } from './AddProductModal';
import { Product } from 'types/product';
import { DeleteProductModal } from './DeleteProductModal';
import { useNotification } from 'hooks/notification';
import { EditProductModal } from './EditProductModal';

const Brand = () => {
  const [isLoadingBrand, setIsLoadingBrand] = useState(true);
  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [brand, setBrand] = useState<BrandWithProducts>();
  const [deleteProduct, setDeleteProduct] = useState<Product>();
  const [editProduct, setEditProduct] = useState<Product>();
  const { id } = useParams<{ id: string }>();
  const { showNotification } = useNotification();

  const handleCloseAddModal = useCallback(() => {
    setIsAddModal(false);
  }, []);

  const handleOpenAddModal = useCallback(() => {
    setIsAddModal(true);
  }, []);

  const handleCloseModalDelete = useCallback(() => {
    setDeleteProduct(undefined);
  }, []);

  const handleOpenModalDelete = useCallback((product: Product) => {
    setDeleteProduct(product);
  }, []);

  const handleCloseModalEdit = useCallback(() => {
    setEditProduct(undefined);
  }, []);

  const handleOpenModalEdit = useCallback((product: Product) => {
    setEditProduct(product);
  }, []);

  const onDeleteProduct = async () => {
    setIsDeletingProduct(true);
    try {
      if (deleteProduct && id) {
        await API.deleteProduct(deleteProduct.id);
        showNotification('Удалено успешно');
        handleCloseModalDelete();
        getBrand(id);
      }
    } catch (error) {
    } finally {
      setIsDeletingProduct(false);
    }
  };

  const getBrand = useCallback(async (id: string) => {
    try {
      const brandId = id && typeof +id === 'number' ? +id : null;
      if (brandId) {
        const { data } = await API.getBrandById(brandId);
        setBrand(data);
      }
    } catch (error) {
    } finally {
      setIsLoadingBrand(false);
    }
  }, []);

  useEffect(() => {
    getBrand(id ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoadingBrand ? (
        <SkeletonLoading count={1} height={80} />
      ) : (
        <BrandHeader brand={brand} onOpenModal={handleOpenAddModal} />
      )}
      {isLoadingBrand && <SkeletonLoading count={5} />}
      {brand?.products?.map((product, idx, array) => (
        <ProductItem
          key={product.id}
          handleOpenModalDelete={handleOpenModalDelete}
          handleOpenModalEdit={handleOpenModalEdit}
          product={product}
          isLast={array.length - 1 === idx}
        />
      ))}
      {!isLoadingBrand && brand?.products.length === 0 && (
        <Typography>Нет позиций в данном бренде</Typography>
      )}
      <AddProductModal
        getBrand={getBrand}
        brandId={brand?.id}
        isOpen={isAddModal}
        onClose={handleCloseAddModal}
      />
      <DeleteProductModal
        onClose={handleCloseModalDelete}
        onOk={onDeleteProduct}
        isLoading={isDeletingProduct}
        isOpen={Boolean(deleteProduct)}
        product={deleteProduct}
      />
      <EditProductModal
        onClose={handleCloseModalEdit}
        isOpen={Boolean(editProduct)}
        getBrand={getBrand}
        brandId={brand?.id}
        product={editProduct}
      />
    </>
  );
};

export default Brand;
