import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Currency, Price } from 'types/price';
import MenuItem from '@mui/material/MenuItem';
import API from 'lib/api';
import { SkeletonLoading } from 'components/SkeletonLoading';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getCheckedBrandAndProducts, getFilterBrands, mergeBrands, updatePrice } from '../helpers';
import { BrandWithProductsChecked, ProductWithChecked } from 'types/brand';
import { PriceBrand } from '../PriceBrand';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNotification } from 'hooks/notification';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';

const parseBrandsResponse = (data?: string) => {
  if (!data) return [];
  const parseData = JSON.parse(data) as BrandWithProductsChecked[];
  return parseData;
};

export const EditPrice = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [price, setPrice] = useState<Price>();
  const [error, setError] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [brands, setBrands] = useState<BrandWithProductsChecked[]>([]);
  const [isShowAll, setIsShowAll] = useState(false);
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  let { id } = useParams();

  const filterBrands = getFilterBrands(brands, isShowAll);

  const handleChangeShowAll: SwitchBaseProps['onChange'] = (_, checked) => {
    setIsShowAll(checked);
  };

  const handleUpdatePrice = async () => {
    try {
      setIsUpdating(true);
      const payload = {
        ...price,
        data: JSON.stringify(getCheckedBrandAndProducts(brands)),
      };
      const { request } = await API.updatePrice(id ?? '', payload);

      if (request.status >= 200 && request.status < 300) {
        showNotification('Прайс успешно обновлен');
        navigate('/');
      }
    } catch (error) {
    } finally {
      setIsUpdating(false);
    }
  };

  const handleChangePageBreaker = (brandId: number, checked: boolean) => {
    setBrands((prevBrands) =>
      prevBrands.map((brand) => {
        if (brand.id === brandId) {
          return {
            ...brand,
            isPageBreak: checked,
          };
        }

        return brand;
      }),
    );
  };

  const handleChangeRoz = (brandId: number, productId: number, value: string) => {
    setBrands((prevBrands) =>
      updatePrice(prevBrands, brandId, productId, value, 'roz', price?.currency),
    );
  };

  const handleChangeOpt = (
    brandId: number,
    productId: number,
    value: string,
    currency: Currency,
  ) => {
    setBrands((prevBrands) => updatePrice(prevBrands, brandId, productId, value, 'opt', currency));
  };

  const handleCheck = useCallback((id: number, checked: boolean) => {
    setBrands((prev) =>
      prev.map((brand) => ({
        ...brand,
        products: brand.products.map((product) => ({
          ...product,
          checked: product.id === id ? checked : product.checked,
        })),
      })),
    );
  }, []);

  const handleCheckAll = useCallback((brandId: number, checked: boolean) => {
    setBrands((data) =>
      data.map((brand) => {
        if (brand.id === brandId) {
          return {
            ...brand,
            products: brand.products.map((product) => ({
              ...product,
              checked,
            })),
          };
        }

        return brand;
      }),
    );
  }, []);

  const handleChangeProduct = useCallback((brandId: number, productId: number, prop: keyof ProductWithChecked, value: unknown) => {
    setBrands((data) =>
    data.map((brand) => {
      if (brand.id === brandId) {
        return {
          ...brand,
          products: brand.products.map((product) => {
            if (product.id === productId) {
              return {
                ...product,
                [prop]: value,
              }
            }

            return product;
          }),
        };
      }

      return brand;
    }),
  );
  }, [])

  const handleChangeFormData = (field: keyof Price, value: unknown) => {
    setPrice((prevPrice) => {
      if (prevPrice) {
        return {
          ...prevPrice,
          [field]: value,
        };
      }

      return prevPrice;
    });
  };

  useEffect(() => {
    const getPriceById = async (id: string) => {
      try {
        Promise.allSettled([
          API.getPriceById(id),
          API.getBrands({ include: 'products', hideEmpty: '' }),
        ]).then(([price, brands]) => {
          if (price.status === 'fulfilled') {
            setPrice(price.value.data);
          }
          if (brands.status === 'fulfilled' && price.status === 'fulfilled') {
            const currentPriceBrands = parseBrandsResponse(price.value.data.data);
            const priceBrands = mergeBrands(currentPriceBrands, brands.value.data);
            setBrands(priceBrands);
          }
        });
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      getPriceById(id);
    }
  }, [id]);

  if (error) {
    return (
      <>
        <Typography sx={{ typography: { xs: 'h6', sm: 'h5', md: 'h4' }, mb: 3 }} gutterBottom>
          <IconButton sx={{ mr: 1 }} onClick={() => navigate('/')}>
            <ArrowBackIcon />
          </IconButton>
          Прайс не найден
        </Typography>
        <Alert severity="error">
          <AlertTitle>Произошла ошибка</AlertTitle>
          Кажется что вы открыли прайс, который не существуют. Перейдите на{' '}
          <Link to="/">главную страницу</Link> и попробуйте снова.
        </Alert>
      </>
    );
  }

  return (
    <div>
      <Typography sx={{ typography: { xs: 'h6', sm: 'h5', md: 'h4' }, mb: 3 }} gutterBottom>
        <IconButton sx={{ mr: 1 }} onClick={() => navigate('/')}>
          <ArrowBackIcon />
        </IconButton>
        Редактирование прайса
      </Typography>
      {isLoading && <SkeletonLoading count={10} />}
      {!isLoading && (
        <>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
            alignItems={{ xs: 'stretch', sm: 'center' }}
            mb={2}
          >
            <TextField
              size="small"
              placeholder="Название прайса"
              value={price?.title}
              onChange={(e) => handleChangeFormData('title', e.target.value)}
              fullWidth
              sx={{ mr: 1 }}
            />
            <Box sx={{ display: 'flex', ml: { xs: 0, sm: 3 }, mt: { xs: 1 } }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={price?.isPublic}
                    onChange={(_, checked) => handleChangeFormData('isPublic', checked)}
                  />
                }
                label="Публичный"
              />
            </Box>
          </Stack>
          <Stack
            flexWrap="wrap"
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'stretch', sm: 'center' }}
            mb={3}
          >
            {price?.type === 'opt' ? (
              <FormControl sx={{ flex: { xs: '1 1 0px' } }} size="small">
                <InputLabel id="currencies">Валюта</InputLabel>
                <Select
                  labelId="currencies"
                  multiple
                  id="typePrice"
                  value={price?.currencies ?? ['rub']}
                  label="Валюта"
                  onChange={(e) => handleChangeFormData('currencies', e.target.value)}
                >
                  <MenuItem value="rub">Рубль</MenuItem>
                  <MenuItem value="eur">Евро</MenuItem>
                  <MenuItem value="usd">Доллар</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <FormControl sx={{ flex: { xs: '1 1 0px' } }} size="small">
                <InputLabel id="currency">Валюта</InputLabel>
                <Select
                  labelId="currency"
                  id="typePrice"
                  value={price?.currency ?? 'usd'}
                  label="Валюта"
                  onChange={(e) => handleChangeFormData('currency', e.target.value)}
                >
                  <MenuItem value="rub">Рубль</MenuItem>
                  <MenuItem value="eur">Евро</MenuItem>
                  <MenuItem value="usd">Доллар</MenuItem>
                </Select>
              </FormControl>
            )}

            <FormControl sx={{ flex: { xs: '1 1 0px' } }} size="small">
              <InputLabel id="typePriceLabel">Тип прайса</InputLabel>
              <Select
                labelId="typePriceLabel"
                id="typePrice"
                value={price?.type ?? 'roz'}
                label="Тип прайса"
                onChange={(e) => handleChangeFormData('type', e.target.value)}
              >
                <MenuItem value="roz">Розница</MenuItem>
                <MenuItem value="opt">Оптом</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ flex: { xs: '1 1 0px' } }} size="small">
              <InputLabel id="themePriceLabel">Вид прайса</InputLabel>
              <Select
                labelId="themePriceLabel"
                id="themePriceLabel"
                value={price?.theme ?? 'black'}
                label="Тип прайса"
                onChange={(e) => handleChangeFormData('theme', e.target.value)}
              >
                <MenuItem value="white">Светлый</MenuItem>
                <MenuItem value="black">Темный</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <FormControlLabel
            control={<Switch checked={isShowAll} onChange={handleChangeShowAll} />}
            label="Показать все бренды"
            sx={{ mb: 2 }}
          />
          {filterBrands.map((brand, idx, array) => (
            <PriceBrand
              key={brand.id}
              brand={brand}
              handleCheck={handleCheck}
              handleCheckAll={handleCheckAll}
              isLast={array.length - 1 === idx}
              currency={price?.currency ?? 'rub'}
              currencies={price?.currencies}
              handleChangeRoz={handleChangeRoz}
              handleChangeOpt={handleChangeOpt}
              handleChangePageBreaker={handleChangePageBreaker}
              type={price?.type ?? 'roz'}
              handleChangeProduct={handleChangeProduct}
            />
          ))}
          <Box sx={{ textAlign: 'right', mt: 5 }}>
            <LoadingButton
              variant="contained"
              size="large"
              loading={isUpdating}
              onClick={handleUpdatePrice}
            >
              Обновить
            </LoadingButton>
          </Box>
        </>
      )}
    </div>
  );
};
