import { BrandWithProducts, BrandWithProductsChecked, ProductWithChecked } from 'types/brand';
import { Currency } from './Add';

export const formatBrands = (data: BrandWithProducts[]): BrandWithProductsChecked[] => {
  return data.map((brand) => ({
    ...brand,
    products: brand.products.map((product) => ({
      ...product,
      checked: true,
    })),
  }));
};

export const getFilterBrands = (brands: BrandWithProductsChecked[], isShowAll: boolean) => {
  if (!brands) return [];
  if (!isShowAll) {
    return brands.reduce<BrandWithProductsChecked[]>((acc, brand) => {
      const newProducts = brand.products.filter((product) => product.checked);

      if (newProducts.length > 0) {
        const newBrand = {
          ...brand,
          products: newProducts,
        };

        acc.push(newBrand);
      }

      return acc;
    }, []);
  }
  return brands;
};

export const setBrandsCheckedFlag = (
  data: BrandWithProducts[],
  checked: boolean,
): BrandWithProductsChecked[] => {
  return data.map((brand) => ({
    ...brand,
    products: brand.products.map((product) => ({
      ...product,
      checked,
    })),
  }));
};

export const parseBrands = (data?: string) => {
  if (!data) return [];
  const parseData = JSON.parse(data) as BrandWithProducts[];
  return setBrandsCheckedFlag(parseData, true);
};

export const mergeBrands = (
  currentBrands: BrandWithProductsChecked[],
  lastBrands: BrandWithProducts[],
) => {
  const newBrands = lastBrands.filter(
    (brand) => !currentBrands.find(({ name }) => name.toLowerCase() === brand.name.toLowerCase()),
  );
  const newBrandsWithCheckedFlag = setBrandsCheckedFlag(newBrands, false);

  const brands = currentBrands.map((brand) => {
    const findBrand = lastBrands.find(
      ({ name }) => name.toLowerCase() === brand.name.toLowerCase(),
    );

    if (!findBrand) return brand;

    const newProducts: ProductWithChecked[] = findBrand.products
      .filter(
        (product) =>
          !brand.products.some((p) => p.title.toLowerCase() === product.title.toLowerCase()),
      )
      .map((product) => ({
        ...product,
        checked: false,
      }));
    return {
      ...brand,
      products: [...brand.products, ...newProducts],
    };
  });

  return [...brands, ...newBrandsWithCheckedFlag].sort((a, b) => a.order - b.order);
};

export const updatePrice = (
  brands: BrandWithProductsChecked[],
  brandId: number,
  productId: number,
  value: string | number,
  type: 'opt' | 'roz',
  currency: Currency = 'rub',
) => {
  return brands.map((brand) => {
    if (brand.id === brandId) {
      return {
        ...brand,
        products: brand.products.map((product) => {
          if (product.id === productId) {
            if (type === 'opt') {
              return {
                ...product,
                priceOpt: {
                  ...product.priceOpt,
                  [currency]: value,
                },
              };
            }
            return {
              ...product,
              price: {
                ...product.price,
                [currency]: value,
              },
            };
          }

          return product;
        }),
      };
    }

    return brand;
  });
};

export const getCheckedBrandAndProducts = (brands: BrandWithProductsChecked[]) => {
  return brands.reduce<BrandWithProductsChecked[]>((acc, brand) => {
    const hasChecked = brand.products.some(({ checked }) => checked);

    if (!hasChecked) return acc;

    const newProducts = brand.products.filter(({ checked }) => checked);

    const newBrand = {
      ...brand,
      products: newProducts,
    };

    acc.push(newBrand);
    return acc;
  }, []);
};
