import { Typography } from '@mui/material';
import { Modal } from 'components/Modal';
import { Brand } from 'types/brand';

type DeleteBrandModalProps = {
  onClose: () => void;
  onOk: () => void;
  isOpen: boolean;
  isLoading: boolean;
  brand?: Brand;
};

export const DeleteBrandModal: React.FC<DeleteBrandModalProps> = ({
  onClose,
  isOpen,
  onOk,
  brand,
  isLoading,
}) => (
  <Modal
    title="Удаление бренда"
    isOpen={isOpen}
    onClose={onClose}
    okText="Да, удалить"
    color="error"
    onOk={onOk}
    onSubmit={(e) => e?.preventDefault()}
    isLoading={isLoading}
  >
    <Typography>
      Вы уверены что хотите удалить {brand?.name}? Удалятся так же все позиции которые ранее были
      добавлены в данный бренд.
    </Typography>
  </Modal>
);
