import { ListItem, IconButton, ListItemText, Divider, ListItemIcon, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { Product } from 'types/product';

type ProductItemProps = {
  handleOpenModalDelete: (product: Product) => void;
  handleOpenModalEdit: (product: Product) => void;
  product: Product;
  isLast?: boolean;
};

export const ProductItem: React.FC<ProductItemProps> = ({
  product,
  isLast,
  handleOpenModalDelete,
  handleOpenModalEdit,
}) => {
  return (
    <>
      <ListItem
        sx={{ paddingRight: 11 }}
        secondaryAction={
          <Stack direction="row" spacing={1}>
            <IconButton edge="end" aria-label="edit" onClick={() => handleOpenModalEdit(product)}>
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleOpenModalDelete(product)}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        }
        disableGutters
      >
        <ListItemIcon sx={{ minWidth: 40 }}>
          <InsertDriveFileIcon />
        </ListItemIcon>
        <ListItemText primary={product.title} />
      </ListItem>
      {!isLast && <Divider />}
    </>
  );
};
