import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Price as TPrice } from 'types/price';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';

export const PriceItem: React.FC<{ price: TPrice; onDelete: (price: TPrice) => void }> = ({
  price,
  onDelete,
}) => {
  const navigate = useNavigate();

  const handleOpenLink = () => {
    window.open(price.priceUrl, '_blank');
  };

  const handleEditPrice = (id: number) => {
    navigate(`/price/${id}`);
  };
  return (
    <ListItem
      sx={{
        '&:nth-child(even)': { bgcolor: 'action.hover' },
        paddingRight: price.isPublic ? 21 : 16,
      }}
      secondaryAction={
        <>
          {price.isPublic && (
            <Tooltip title="Это публичный прайс">
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            edge="end"
            aria-label="delete"
            sx={{ mr: 0.5 }}
            onClick={() => handleEditPrice(price.id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton edge="end" aria-label="delete" sx={{ mr: 0.5 }} onClick={handleOpenLink}>
            <PictureAsPdfIcon />
          </IconButton>
          <IconButton edge="end" aria-label="delete" onClick={() => onDelete(price)}>
            <DeleteIcon />
          </IconButton>
        </>
      }
    >
      <ListItemText primary={price.title} />
    </ListItem>
  );
};
