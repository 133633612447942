import { Role as TRole } from 'types/user';

export const Role = {
  admin: 'ADMIN',
  employee: 'EMPLOYEE',
  user: 'USER',
};

export const isAdmin = (role: TRole) => role === 'ADMIN' || role === 'EMPLOYEE';
export const isUser = (role: TRole) => role === 'USER';
