import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Modal } from 'components/Modal';
import { useNotification } from 'hooks/notification';
import API from 'lib/api';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Brand } from 'types/brand';

type Inputs = {
  name: string;
  order: string;
  isPageBreak: boolean;
};

type UpdateBrandModalProps = {
  onClose: () => void;
  isOpen: boolean;
  brand?: Brand;
  getBrands: () => void;
};

export const UpdateBrandModal: React.FC<UpdateBrandModalProps> = ({
  onClose,
  isOpen,
  brand,
  getBrands,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm<Inputs>({ mode: 'onChange' });
  const [isEditing, setisEditing] = useState(false);
  const { showNotification } = useNotification();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setisEditing(true);
    try {
      if (brand) {
        const payload = {
          name: data.name,
          order: +data.order,
          isPageBreak: data.isPageBreak,
        };
        await API.updateBrand(String(brand.id), payload);
        showNotification('Данные успешно обновлены');
        onClose();
        getBrands();
      }
    } catch (error) {
    } finally {
      setisEditing(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        name: brand?.name ?? '',
        order: String(brand?.order),
        isPageBreak: brand?.isPageBreak,
      });
    }
  }, [brand?.isPageBreak, brand?.name, brand?.order, isOpen, reset]);

  return (
    <Modal
      title="Редактировать бренд"
      onClose={onClose}
      isOpen={isOpen}
      onSubmit={handleSubmit(onSubmit)}
      okText="Обновить"
      isLoading={isEditing}
      okDisabled={!isValid}
      maxWidth="sm"
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            required
            label="Название бренда"
            defaultValue=""
            error={Boolean(errors.name?.message)}
            helperText={errors.name?.message}
            {...register('name', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Порядок"
            type="number"
            fullWidth
            required
            error={Boolean(errors.order?.message)}
            helperText={errors.order?.message}
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue="0"
            {...register('order', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Controller
                name="isPageBreak"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={!!field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Перенос на новую страницу"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
