import Typography from '@mui/material/Typography';
// import { AdminLayout } from 'components/AdminLayout';
import { SkeletonLoading } from 'components/SkeletonLoading';
import API from 'lib/api';
import { useCallback, useEffect, useState } from 'react';
import { Price } from 'types/price';
import { PriceItem } from './Price';
// import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import './Price.css';
import { DeletePriceModal } from './DeletePriceModal';
import { useNotification } from 'hooks/notification';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const Prices = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [prices, setPrices] = useState<Price[]>([]);
  const [deleteItem, setDeleteItem] = useState<Price>();
  const [isDeleting, setIsDeleting] = useState(false);

  const { showNotification } = useNotification();

  const handleCloseModalDelete = () => {
    setDeleteItem(undefined);
  };

  const handleOpenModalDelete = (price: Price) => {
    setDeleteItem(price);
  };

  const getPrices = useCallback(async () => {
    try {
      const { data } = await API.getPrices();
      const sortedPrices = [...data].sort((a, b) => b.id - a.id);
      setPrices(sortedPrices);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onDeleteBrand = async () => {
    setIsDeleting(true);
    try {
      if (deleteItem) {
        await API.deletePrice(deleteItem?.id);
        showNotification(`Бренд ${deleteItem?.title} удален успешно`);
        handleCloseModalDelete();
        getPrices();
      }
    } catch (error) {
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    getPrices();
  }, [getPrices]);
  return (
    <>
      <Typography sx={{ typography: { xs: 'h6', sm: 'h5', md: 'h4' } }} gutterBottom>
        Список прайсов
      </Typography>
      {isLoading && <SkeletonLoading count={5} />}
      {!isLoading && (
        <List>
          {prices.map((price) => (
            <PriceItem key={price.id} price={price} onDelete={handleOpenModalDelete} />
          ))}
        </List>
      )}
      {!isLoading && prices.length === 0 && (
        <>
          <Typography>
            Нет добавленных прайсов. Это можно легко исправить,{' '}
            <Link component={RouterLink} to="/price-add">
              добавив новый прайс.
            </Link>
          </Typography>
        </>
      )}
      <DeletePriceModal
        onOk={onDeleteBrand}
        onClose={handleCloseModalDelete}
        isOpen={Boolean(deleteItem)}
        price={deleteItem}
        isLoading={isDeleting}
      />
    </>
  );
};
