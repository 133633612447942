import { AlertColor } from '@mui/material';
import React from 'react';

interface NotificationContextType {
  message: string;
  type: AlertColor;
  setType: (type: AlertColor) => void;
  setMessage: (message: string) => void;
  showNotification: (msg?: string, type?: AlertColor) => void;
  closeNotification: () => void;
  isOpen: boolean;
  delay: number;
}

const DELAY = 4000;
const DELAY_AFTER_RESET = 1000;

const NotificationContext = React.createContext<NotificationContextType>(null!);

export function useNotification() {
  return React.useContext(NotificationContext);
}

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [message, setMessageState] = React.useState('');
  const [type, setTypeState] = React.useState<AlertColor>('info');

  const showNotification = (message?: string, type?: AlertColor) => {
    setIsOpen(true);
    if (message) setMessage(message);
    if (type) setType(type);
  };
  const closeNotification = () => setIsOpen(false);

  const setMessage = (msg: string) => {
    setMessageState(msg);
    setTimeout(() => {
      setIsOpen(false);
      setMessageState('');
    }, DELAY + DELAY_AFTER_RESET);
  };

  const setType = (type: AlertColor) => {
    setTypeState(type);
  };

  const value = {
    message,
    type,
    setType,
    delay: DELAY,
    setMessage,
    isOpen,
    showNotification,
    closeNotification,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
