import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { BrandWithProducts } from 'types/brand';
import { Price } from 'types/price';

export const Item: React.FC<{ brand: BrandWithProducts; price: Price }> = ({ brand, price }) => (
  <Box sx={{ mb: { xs: 4, sm: 6 } }}>
    <Typography
      variant="h5"
      component="span"
      sx={{
        color: 'text.primary',
        background: '#DCB0FF',
        mb: 2,
        display: 'inline-block',
        padding: '0 10px',
      }}
    >
      {brand.name}
    </Typography>
    {brand.products.map((product) => (
      <Box
        key={product.id}
        sx={{ display: 'flex', color: 'text.secondary', mb: 0.5, fontSize: 14 }}
      >
        <Box sx={{ flex: 1 }}>{product.title}</Box>
        <Box sx={{ flexBasis: 150 }}>{product.qty}</Box>
        <Box sx={{ flexBasis: 80 }}>{product.price[price.currency]}</Box>
      </Box>
    ))}
  </Box>
);
