import { useContext, useState } from 'react';
import {
  Avatar,
  Box,
  ButtonBase,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useAuth } from 'hooks/auth';
import { Logout } from '@mui/icons-material';
import { clearStorageTokens } from 'lib/auth';
import { useNavigate } from 'react-router-dom';
import ColorModeContext from 'ThemeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

export const UserInfo = () => {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    handleClose();
    clearStorageTokens();
    auth.setUser(null);
    navigate('/');
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end">
      <Tooltip title="Сменить тему">
        <IconButton
          sx={{ ml: 1, mr: { xs: 1, sm: 2 } }}
          onClick={colorMode.toggleColorMode}
          color="inherit"
        >
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Tooltip>
      <ButtonBase
        onClick={handleClick}
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', alignSelf: 'center' }}
      >
        <Avatar>{auth?.user?.name?.[0] ?? '??'}</Avatar>
        <Box sx={{ marginLeft: 1 }}>{auth?.user?.name || 'Anonymous'}</Box>
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Выйти
        </MenuItem>
      </Menu>
    </Stack>
  );
};
