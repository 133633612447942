import { Skeleton } from '@mui/material';

const rangeNumbers = (n: number) => Array.from({ length: n }, (v, k) => k);

export const SkeletonLoading: React.FC<{ count: number; height?: number }> = ({
  count = 3,
  height = 40,
}) => {
  const items = rangeNumbers(count);
  return (
    <>
      {items.map((key) => (
        <Skeleton animation="wave" key={key} height={height} />
      ))}
    </>
  );
};
