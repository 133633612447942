import { Typography } from '@mui/material';
import { Modal } from 'components/Modal';
import { Product } from 'types/product';

type DeleteProductModalProps = {
  onClose: () => void;
  onOk: () => void;
  isOpen: boolean;
  isLoading: boolean;
  product?: Product;
};

export const DeleteProductModal: React.FC<DeleteProductModalProps> = ({
  onClose,
  isOpen,
  onOk,
  product,
  isLoading,
}) => (
  <Modal
    title="Удаление позиции"
    isOpen={isOpen}
    onClose={onClose}
    okText="Да, удалить"
    color="error"
    onOk={onOk}
    onSubmit={(e) => e?.preventDefault()}
    isLoading={isLoading}
  >
    <Typography>Вы уверены что хотите удалить {product?.title}?</Typography>
  </Modal>
);
