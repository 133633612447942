import { useAuth } from 'hooks/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { storage } from './storage';

type ProtectRouteProps = {
  children: JSX.Element;
  roles: string[];
  fallback?: JSX.Element;
};

export function ProtectRoute({ children, roles, fallback }: ProtectRouteProps) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth || !auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (auth && !roles.includes(auth.user.role)) {
    if (fallback) return fallback;
    return <Navigate to="/" />;
  }

  return children;
}

export const setStorageTokens = (accessToken: string, refreshToken: string) => {
  storage.set('token', accessToken);
  storage.set('refresh_token', refreshToken);
};

export const clearStorageTokens = () => {
  storage.remove('token');
  storage.remove('refresh_token');
};
