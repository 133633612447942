import Typography from '@mui/material/Typography';
import { Modal } from 'components/Modal';
import { User } from 'types/user';

type DeleteUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
  isLoading: boolean;
  user?: User;
};

export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  isOpen,
  onClose,
  user,
  onOk,
  isLoading,
}) => (
  <Modal
    title="Удаление пользователя"
    isOpen={isOpen}
    onClose={onClose}
    okText="Да, удалить"
    color="error"
    onOk={onOk}
    onSubmit={(e) => e?.preventDefault()}
    isLoading={isLoading}
  >
    <Typography>
      Вы уверены что хотите удалить пользователя <strong>{user?.name}</strong>?
    </Typography>
  </Modal>
);
