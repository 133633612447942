import { Box } from '@mui/material';
import { Logo } from 'components/Logo';
import { UserInfo } from './UserInfo';

export const Header = () => {
  return (
    <Box
      sx={{
        paddingTop: 5,

        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ width: { xs: 150, sm: 200 } }}>
        <Logo />
      </Box>
      <UserInfo />
    </Box>
  );
};
