import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import HomePage from './pages/Home';
import Register from './pages/Register';

import { ProtectRoute } from 'lib/auth';
import API from 'lib/api';
import { useAuth } from 'hooks/auth';
import { Spinner } from 'components/Spinner';
import { Box, useTheme } from '@mui/material';
import { storage } from 'lib/storage';
import { Login } from 'pages/Login';
import RecoveryPage from 'pages/Recovery';
import ConfirmPassword from 'pages/ConfirmPassword';
import Brands from 'pages/Brands';
import Brand from 'pages/Brand';
import PriceAdd from 'pages/Price/Add';
import Users from 'pages/Users';
import { Role } from 'helpers/permission';
import { EditPrice } from 'pages/Price/Edit';

const App = () => {
  const [loading, setLoading] = useState(true);
  const { setUser } = useAuth();
  const { palette } = useTheme();

  useEffect(() => {
    const getMe = async () => {
      try {
        const token = storage.get('token');

        if (token) {
          const { data } = await API.me();
          setUser(data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <Box
        sx={{
          bgcolor: palette.mode === 'light' ? '#f5f5f5' : 'background.default',
          color: 'text.primary',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner text="Идет загрузка сайта.." mode={palette.mode} />
      </Box>
    );
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/recovery" element={<RecoveryPage />} />
        <Route path="/confirm-password" element={<ConfirmPassword />} />
        <Route
          path="/brands"
          element={
            <ProtectRoute roles={[Role.admin, Role.employee]}>
              <Brands />
            </ProtectRoute>
          }
        />
        <Route
          path="/price/:id"
          element={
            <ProtectRoute roles={[Role.admin, Role.employee]}>
              <EditPrice />
            </ProtectRoute>
          }
        />
        <Route
          path="/brand/:id"
          element={
            <ProtectRoute roles={[Role.admin, Role.employee]}>
              <Brand />
            </ProtectRoute>
          }
        />
        <Route
          path="/price-add"
          element={
            <ProtectRoute roles={[Role.admin, Role.employee]}>
              <PriceAdd />
            </ProtectRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectRoute roles={[Role.admin]}>
              <Users />
            </ProtectRoute>
          }
        />
      </Route>
    </Routes>
  );
};

export default App;
