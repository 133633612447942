import { Logo } from 'components/Logo';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box } from '@mui/material';

export const HomeHeader = () => (
  <div className="home-header">
    <div className="home-header-logo">
      <Logo />
    </div>
    <div className="tels">
      <Box sx={{ display: 'flex', mb: 1, '& a': { color: 'text.primary' } }}>
        <WhatsAppIcon sx={{ mr: 1, color: 'action.disabled' }} />
        <a href="https://wa.me/447593160593" target="_blank" rel="noreferrer">
          +44 7593 160593
        </a>
      </Box>
      <Box sx={{ display: 'flex', mb: 1, '& a': { color: 'text.primary' } }}>
        <WhatsAppIcon sx={{ mr: 1, color: 'action.disabled' }} />
        <a href="https://wa.me/447908436911" target="_blank" rel="noreferrer">
          +44 7908 436911
        </a>
      </Box>
      <Box sx={{ display: 'flex', mb: 1, '& a': { color: 'text.primary' } }}>
        <WhatsAppIcon sx={{ mr: 1, color: 'action.disabled' }} />
        <a href="https://wa.me/447495925236" target="_blank" rel="noreferrer">
          +44 7495 925236
        </a>
      </Box>
    </div>
  </div>
);
