import {
  ListItem,
  IconButton,
  ListItemText,
  Divider,
  ListItemIcon,
  ListItemButton,
  Stack,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { Brand } from 'types/brand';
import { useNavigate } from 'react-router-dom';

export const BrandItem: React.FC<{
  brand: Brand;
  isLast?: boolean;
  handleOpenModalDelete: (brand: Brand) => void;
  handleOpenModalUpdate: (brand: Brand) => void;
}> = ({ brand, isLast, handleOpenModalDelete, handleOpenModalUpdate }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/brand/${brand.id}`);
  };
  return (
    <>
      <ListItem
        secondaryAction={
          <Stack direction="row" spacing={1}>
            {brand.isPageBreak && (
              <Tooltip title="Бренд переносится на новую страницу">
                <IconButton edge="end">
                  <SwapVertIcon />
                </IconButton>
              </Tooltip>
            )}
            <IconButton
              edge="end"
              aria-label="редактироваие"
              onClick={() => handleOpenModalUpdate(brand)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="удаление"
              onClick={() => handleOpenModalDelete(brand)}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        }
        disableGutters
      >
        <ListItemButton
          dense
          onClick={handleClick}
          sx={{ marginRight: brand.isPageBreak ? '80px' : '40px', paddingRight: 0 }}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary={brand.name} />
        </ListItemButton>
      </ListItem>
      {!isLast && <Divider />}
    </>
  );
};
