import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './global.css';
import { CssBaseline } from '@mui/material';
import { AuthProvider } from 'hooks/auth';
import { NotificationProvider } from 'hooks/notification';
import { Notification } from 'components/Notification';
import ToggleColorMode from 'ToggleColorMode';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <NotificationProvider>
      <AuthProvider>
        <BrowserRouter>
          <>
            <CssBaseline />
            <Notification />
            <ToggleColorMode>
              <App />
            </ToggleColorMode>
          </>
        </BrowserRouter>
      </AuthProvider>
    </NotificationProvider>
  </React.StrictMode>,
);

reportWebVitals();
