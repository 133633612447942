import { FormControlLabel, Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Modal } from 'components/Modal';
import { useNotification } from 'hooks/notification';
import API from 'lib/api';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Product } from 'types/product';

type EditProductModalProps = {
  isOpen: boolean;
  onClose: () => void;
  product?: Product;
  brandId?: number;
  getBrand: (id: string) => void;
};

type Inputs = {
  order: string;
  title: string;
  priceRub: string;
  priceEur: string;
  priceUsd: string;
  priceOptRub: string;
  priceOptEur: string;
  priceOptUsd: string;
  qty: string;
  new: boolean;
  promo: boolean;
};

export const EditProductModal: React.FC<EditProductModalProps> = ({
  isOpen,
  onClose,
  product,
  getBrand,
  brandId,
}) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm<Inputs>({ mode: 'onChange' });
  const { showNotification } = useNotification();

  const [isSending, setIsSending] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsSending(true);
    try {
      const payload = {
        order: +data.order,
        title: data.title,
        qty: data.qty,
        price: {
          eur: +data.priceEur,
          usd: +data.priceUsd,
          rub: +data.priceRub,
        },
        priceOpt: {
          eur: +data.priceOptEur,
          usd: +data.priceOptUsd,
          rub: +data.priceOptRub,
        },
        new: data.new,
        promo: data.promo,
      };
      const { request } = await API.updateProduct(product?.id, payload);
      if (request.status === 200) {
        getBrand(String(brandId));
        reset();
        onClose();
        showNotification(`${data.title} успешно обновлено`);
      }
    } catch (error) {
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    } else {
      reset({
        title: product?.title,
        qty: product?.qty,
        priceEur: String(product?.price?.eur ?? 0),
        priceRub: String(product?.price?.rub ?? 0),
        priceUsd: String(product?.price?.usd ?? 0),
        priceOptEur: String(product?.priceOpt?.eur ?? 0),
        priceOptRub: String(product?.priceOpt?.rub ?? 0),
        priceOptUsd: String(product?.priceOpt?.usd ?? 0),
        order: String(product?.order ?? 0),
        new: Boolean(product?.new),
        promo: Boolean(product?.promo),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  return (
    <Modal
      title="Редактировать позицию"
      isOpen={isOpen}
      onClose={onClose}
      okText="Обновить"
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isSending}
      okDisabled={!isValid}
      maxWidth="sm"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <TextField
            fullWidth
            required
            label="Название"
            defaultValue={product?.title ?? ''}
            error={Boolean(errors.title?.message)}
            helperText={errors.title?.message}
            {...register('title', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField label="Кол/об" defaultValue={product?.qty ?? ''} {...register('qty')} />
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            label="Порядок"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={product?.order ?? 0}
            {...register('order')}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" mt={2} mb={1}>
        Розничная цена
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="₽"
            defaultValue={product?.price?.rub ?? 0}
            error={Boolean(errors.priceRub?.message)}
            helperText={errors.priceRub?.message}
            {...register('priceRub', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="€"
            defaultValue={product?.price?.eur ?? 0}
            error={Boolean(errors.priceEur?.message)}
            helperText={errors.priceEur?.message}
            {...register('priceEur', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="$"
            defaultValue={product?.price?.usd ?? 0}
            error={Boolean(errors.priceUsd?.message)}
            helperText={errors.priceUsd?.message}
            {...register('priceUsd', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" mt={2} mb={1}>
        Оптовая цена
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="₽"
            defaultValue={product?.priceOpt?.rub ?? 0}
            error={Boolean(errors.priceOptRub?.message)}
            helperText={errors.priceOptRub?.message}
            {...register('priceOptRub', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="€"
            defaultValue={product?.priceOpt?.eur ?? 0}
            error={Boolean(errors.priceOptEur?.message)}
            helperText={errors.priceOptEur?.message}
            {...register('priceOptEur', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="$"
            defaultValue={product?.priceOpt?.usd ?? 0}
            error={Boolean(errors.priceOptUsd?.message)}
            helperText={errors.priceOptUsd?.message}
            {...register('priceOptUsd', { required: 'Поле обязательно для заполнения' })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControlLabel
            control={<Switch {...register('new')} defaultChecked={product?.new} />}
            label="Новый товар"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={<Switch {...register('promo')} defaultChecked={product?.promo} />}
            label="Акция"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
