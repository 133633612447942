import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type PriceTextProps = {
  value: string | number;
  symbol: string;
  width?: number;
};

export const PriceText: React.FC<PriceTextProps> = ({ value, symbol, width }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <Typography
        sx={{ display: 'inline', mr: 1, width, textAlign: { xs: 'left', lg: 'right' } }}
        component="span"
        variant="body2"
        color="text.primary"
      >
        {value}
        {symbol}
      </Typography>
    </Box>
  );
};
