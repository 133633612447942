import axios from 'axios';
import { storage } from './storage';
import API from './api';
import { setStorageTokens } from './auth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${storage.get('token')}`;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const refresh_token = storage.get('refresh_token');
    const isRefreshTokenUrl = error.config.url.includes('refresh-token');
    if (!refresh_token) {
      return error;
    }
    if (error.response.status === 401 && isRefreshTokenUrl) {
      storage.remove('refresh_token');
      storage.remove('token');
    }
    if (error.response.status === 401 && !originalRequest._retry && !isRefreshTokenUrl) {
      originalRequest._retry = false;

      try {
        const { data, request } = await API.refreshAccessToken(refresh_token ?? '');
        if (request.status < 300) {
          instance.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
          setStorageTokens(data.access_token, data.refresh_token);
          return instance(originalRequest);
        }
      } catch (error) {}
    }
    return error;
  },
);

export default instance;
