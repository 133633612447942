import { Typography } from '@mui/material';
import { Modal } from 'components/Modal';
import { Price } from 'types/price';

type DeletePriceModalProps = {
  onClose: () => void;
  onOk: () => void;
  isOpen: boolean;
  isLoading: boolean;
  price?: Price;
};

export const DeletePriceModal: React.FC<DeletePriceModalProps> = ({
  onClose,
  isOpen,
  onOk,
  price,
  isLoading,
}) => (
  <Modal
    title="Удаление прайса"
    isOpen={isOpen}
    onClose={onClose}
    okText="Да, удалить"
    color="error"
    onOk={onOk}
    onSubmit={(e) => e?.preventDefault()}
    isLoading={isLoading}
  >
    <Typography>Вы уверены что хотите удалить {price?.title}?</Typography>
  </Modal>
);
