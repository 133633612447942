import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { AdminLayout } from 'components/AdminLayout';
import { useAuth } from 'hooks/auth';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const Layout: React.FC = () => {
  const { palette } = useTheme();

  const { user } = useAuth();

  if (!user) {
    return (
      <Box
        sx={{
          bgcolor: palette.mode === 'light' ? '#f5f5f5' : 'background.default',
          color: 'text.primary',
          minHeight: '100vh',
        }}
      >
        <Outlet />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        bgcolor: palette.mode === 'light' ? '#f5f5f5' : 'background.default',
        color: 'text.primary',
        minHeight: '100vh',
      }}
    >
      <AdminLayout>
        <Paper elevation={3} sx={{ p: { xs: 2, sm: 3, md: 5 } }}>
          <Outlet />
        </Paper>
      </AdminLayout>
    </Box>
  );
};
