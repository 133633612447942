import React, { FormEvent } from 'react';
import Button from '@mui/material/Button';
import { Breakpoint, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

type ModalProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onOk?: () => void;
  children?: React.ReactNode;
  cancelText?: string;
  okText?: string;
  maxWidth?: Breakpoint;
  onSubmit?: (event?: FormEvent<HTMLFormElement>) => void;
  isLoading?: boolean;
  okDisabled?: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
};

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onOk,
  children,
  title,
  cancelText = 'Отменить',
  okText = 'Сохранить',
  maxWidth = 'xs',
  onSubmit,
  isLoading,
  okDisabled,
  color = 'primary',
}) => {
  return (
    <BootstrapDialog
      maxWidth={maxWidth}
      fullWidth
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={onSubmit} id="qq">
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            {cancelText}
          </Button>
          <LoadingButton
            loading={isLoading}
            autoFocus
            onClick={onOk}
            variant="contained"
            disabled={okDisabled}
            color={color}
            type="submit"
          >
            {okText}
          </LoadingButton>
        </DialogActions>
      </form>
    </BootstrapDialog>
  );
};
